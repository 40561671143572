*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background: rgb(27, 27, 31)
}

body {
    overflow-x: hidden;
}

.webgl
{
    position: absolute;
    top: 0;
    right: 0;
    outline: none;
    mix-blend-mode: exclusion;
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: white;
    margin: 16em 8em;
}

h1 {
    font-size: 4rem;
    text-transform: uppercase;
    color: white;
}

p {
    font-size: 1.3rem;
    width: 55%;
    color: rgb(107, 107, 122)
}

button {
    font-size: 1.3rem;
    padding: .5em 1em;
    margin-top: 3em;
    background-color: rgb(0, 114, 255);
    border: none;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}